import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

import imgSlide01 from "../../images/slides/music-republicain-1.jpg";
import imgSlide02 from "../../images/slides/music-republicain-2.jpg";
import imgSlide03 from "../../images/slides/music-republicain-3.jpg";
import imgSlide04 from "../../images/slides/music-republicain-4.jpg";
import imgSlide05 from "../../images/slides/music-republicain-5.jpg";

const HeroCarousel = () => {
  const DATA_SLIDES = [imgSlide01, imgSlide02, imgSlide03, imgSlide04, imgSlide05];

  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleSlide = (e) => {
    setDirection(e.direction);
  };

  const createItems = () => {
    let items = [];
    for (let index = 0; index < DATA_SLIDES.length; index++) {
      items.push(
        <Carousel.Item key={index}>
          <img className="d-block w-100" src={DATA_SLIDES[index]} alt="" />
        </Carousel.Item>
      );
    }
    return items;
  };

  return (
    <Carousel activeIndex={index} direction={direction} onSelect={handleSelect} onSlide={handleSlide}>
      {createItems()}
    </Carousel>
  );
};

export default () => (
  <section className="banner_part">
    <HeroCarousel />
    <div className="banner_text">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 offset-lg-1">
            {/* <div className="banner_text_iner">
              <h1 className="brand">Music Republicain</h1>
              <h5 className="pl-lg-2">
                It is never too young and never too old to explore your inner
                music talent!
              </h5>
              <a href="#" className="btn_1">Explore Work</a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </section>
);
